
<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="快船翻单" name="first" />
      <el-tab-pane label="普船翻单" name="second" />
    </el-tabs>
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;  flex-wrap: wrap; margin-top: 15px" :rules="queryRule">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item label="销售预测版本" :class="$i18n.locale" prop="versionCode">
              <el-select v-model="form.versionCode" clearable filterable :placeholder="$t('page.selectPlaceholder')" style="width:100%;" @change="getVersionCode">
                <el-option
                  v-for="item in dsbVertionOptions"
                  :key="item.versionCode"
                  :label="item.versionCode"
                  :value="item.versionCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="备货渠道">
              <el-select
                v-model="form.stockChannelIdList"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
                style="width:100%;"
              >
                <el-option
                  v-for="item in StockChannelOptions"
                  :key="item.channelId"
                  :label="item.channelName"
                  :value="item.channelId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="Style">
              <el-select
                v-model="form.styleIdList"
                value-key="id"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
                style="width:100%;"
                @change="handleStyleChange"
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Color" :class="$i18n.locale">
              <el-select
                v-model="form.colorList"
                clearable
                multiple
                filterable
                collapse-tags
                id:placeholder="$t('page.selectPlaceholder')"
                style="width:100%;"
              >
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item label="Size" :class="$i18n.locale">
              <el-select
                v-model="form.sizeList"
                clearable
                filterable
                multiple
                collapse-tags
                id:placeholder="$t('page.selectPlaceholder')"
                style="width:100%;"
              >
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.size"
                  :value="item.size"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="类别" :class="$i18n.locale">
              <el-cascader
                v-model="form.category"
                clearable
                collapse-tags
                filterable
                separator=">"
                :filter-method="dataFilter"
                :options="categoryOptions"
                :props=" { value: 'id', label: 'categoryName', children: 'sonCategory', multiple:true}"
                style="width:100%;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="季节" :class="$i18n.locale">
              <el-select v-model="form.seasonIdList" clearable filterable collapse-tags multiple :placeholder="$t('page.selectPlaceholder')" style="width:100%;">
                <el-option
                  v-for="item in _getAllCommodityDict('SEASON')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="定位" :class="$i18n.locale">
              <Select
                v-model="form.positionIdList"
                style="width:100%;"
                api-key="position"
                clearable
                filterable
                collapse-tags
                multiple
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="品牌">
              <el-select
                v-model="form.brandIdList"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
                style="width:100%;"
              >
                <el-option
                  v-for="item in brandOptions"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Sku" :class="$i18n.locale">
              <el-input
                v-model="form.skuList"
                placeholder="多个以换行符分隔"
                type="textarea"
                autosize
                resize="none"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="供应商">
              <el-select v-model="form.vendorIdList" value-key="id" clearable filterable multiple collapse-tags style="width:100%;">
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="el-rt header_btn">
        <el-button v-if="isNew" v-permission="'repeatOrder'" :loading="getProduceloading" class="ml-4" type="primary" @click="toModify">翻单</el-button>
        <div class="row-center">
          <el-form-item label="翻单周期" prop="replenishCycle" class="header_replenishCycle">
            <el-input v-model="form.replenishCycle" oninput="value=value.replace(/[^0-9]/g,'')" :disabled="!isNew" style="width:85%" @blur="replenishment" />天
          </el-form-item>
          <el-button type="primary" :loading="Loading" class="ml-4" @click="queryClick(1)">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button v-permission="'exportA'" :loading="exportLoading" class="ml-4" type="primary" @click="exportDetail">导出</el-button>
        </div>
      </div>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="supplierTableLoading"
      :data="tableDatas"
      style="width: 100%"
      row-key="id"
      max-height="600px"
      :header-cell-style="{background:'#fafafa'}"
      @select="handleSelect"
      @expand-change="handleSelectionChange"
      @select-all="handleSelectAll"
    >
      <el-table-column type="selection" />
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="my_table">
            <el-table
              :ref="'tableColor' + props.row.id"
              style="margin-left: 30px"
              :header-cell-style="{background:'#fafafa'}"
              :header-cell-class-name="cellClass"
              :data="props.row.colorDTOList"
              row-key="defaultKey"
              @select="handleExpandSelect"
              @expand-change="handleSelectionSizeChange"
            >
              <el-table-column
                type="selection"
                width="50"
              />
              <el-table-column type="expand">
                <template slot-scope="inprops">
                  <div class="my_table">
                    <el-table
                      :ref="'tableSize' + inprops.row.defaultKey"
                      style="margin-left: 60px"
                      :header-cell-style="{background:'#fafafa'}"
                      :header-cell-class-name="cellClass"
                      :data="inprops.row.sizeDTOList"
                      @select="handleSizeSelect"
                    >
                      <el-table-column
                        type="selection"
                        width="50"
                      />
                      <el-table-column
                        label="序号"
                        type="index"
                        width="50"
                        align="center"
                      />
                      <template v-for="(col, idx) in SizeColumns">
                        <slot v-if="col.slot" :name="col.slot" />
                        <el-table-column
                          v-else
                          :key="idx"
                          :prop="col.prop"
                          :label="col.label"
                          :width="col.width"
                          :formatter="col.formatter"
                          align="center"
                        />
                      </template>
                    </el-table>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="序号"
                type="index"
                width="50"
                align="center"
              />
              <template v-for="(col, idx) in ColorColumns">
                <slot v-if="col.slot" :name="col.slot" />
                <el-table-column
                  v-else
                  :key="idx"
                  :prop="col.prop"
                  :label="col.label"
                  :width="col.width"
                  :formatter="col.formatter"
                  align="center"
                />
              </template>
              <el-table-column label="操作" width="90" align="center">
                <template slot-scope="scope">
                  <el-button
                    v-if="isNew"
                    :loading="getProduceloading"
                    type="text"
                    size="small"
                    @click="toModifyDetail(scope.row, 'color')"
                  >翻单
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <template v-for="(col, index) in StyleColumns">
        <!-- 操作列/自定义列 -->
        <el-table-column
          :key="index"
          :prop="col.prop"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
          align="center"
        />
      </template>
      <el-table-column :label="$t('page.operate')" width="90" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="isNew"
            :loading="getProduceloading"
            type="text"
            size="small"
            @click="toModifyDetail(scope.row, 'style')"
          >翻单
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :pager="pager" end :page-sizes="pageSizes" @pagination="pagerUpdate" />
  </div>
</template>

<script>
import Paging from '@/components/Pagination'
import Select from '@/components/Selection'
import { queryVendorList, dsbVersionList, StockChannelList, queryStyleList, queryColorList, querySizeList,
  querySeasonList, getBaseCategory, listByPage, getProduceCycle, exportProduce, Toproduce } from '@/api/scm-api'
import { queryBrandList } from '@/api/listSelection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { getStyleColumns, getColorColumns, getSizeColumns } from './columns'
import { preText } from '@/utils'

import { handleBreadTitle, trackExportEvent, trackBtnClickEvent } from '@/utils/monitor'
export default {
  components: {
    Paging, Select
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      pageSizes: [10, 20, 30, 40, 50],
      StyleColumns: getStyleColumns(),
      ColorColumns: getColorColumns(),
      SizeColumns: getSizeColumns(),
      dialogVisible: false,
      activeName: 'first',
      detailPager: {
        size: 10,
        current: 1,
        total: 0
      },
      supplierDetailTable: [],
      totalData: {},
      pager: {
        current: 1,
        size: 10,
        pages: 1,
        total: 0
      },
      form: {
        versionCode: '',
        replenishCycle: 14,
        type: 6,
        colorList: [],
        skuList: '',
        stockChannelIdList: [],
        styleIdList: '',
        sizeList: [],
        category: [],
        seasonIdList: [],
        positionIdList: [],
        brandIdList: [],
        vendorIdList: []
      },
      originalValue: 14,
      vendorOptions: [],
      dsbVertionOptions: [],
      StockChannelOptions: [],
      categoryOptions: [],
      styleOptions: [],
      brandOptions: [],
      seasonOptions: [],
      colorOptions: [],
      sizeOptions: [],
      queryRule: {
        versionCode: { required: true, message: this.$t('page.required'), trigger: 'change' },
        replenishCycle: { required: true, message: this.$t('page.required'), trigger: 'blur' }
      },
      Loading: false,
      exportLoading: false,
      getProduceloading: false,
      isNew: true, // 是否显示为最新的销售预测版本
      supplierTableLoading: false,
      tableDatas: [],
      multipleSelection: [],
      multipleSizeSelection: [],
      getVersionLoading: false
    }
  },
  computed: {
    queryParams() {
      const { category, styleIdList, skuList } = this.form
      const lastCategoryIdList = []
      if (Array.isArray(category) && category.length > 0) {
        for (let i = 0; i < category.length; i++) {
          lastCategoryIdList.push(parseInt(category[i].slice(-1)))
        }
      }
      return Object.assign({}, this.form, { lastCategoryIdList,
        styleIdList: styleIdList ? [styleIdList] : [],
        skuList: preText(skuList, true)
      })
    }
  },
  async mounted() {
    this.activeName = this.$store.getters.flipActiveName || 'first'
    const defaultForm = this.$store.getters[`flip`] || {}
    this.form = Object.assign({}, this.$options.data.call(this).form, defaultForm)
    await this.changeWeek()
    this._queryVendorList()
    this._StockChannelList()
    this._dsbVersionList()
    this._queryBrandList()
    this._querySeasonList()
    this._queryStyleList()
    this._queryColorList(this.form.styleIdList)
    this._querySizeList(this.form.styleIdList)
    this._CategoryList()
  },
  methods: {
    dataFilter(node, val) {
      if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
        return true
      }
    },
    async handleClick() {
      if (this.activeName === 'second') {
        this.form.type = 7
      } else {
        this.form.type = 6
      }
      this.isNew = true
      this.form.versionCode = this.dsbVertionOptions[this.dsbVertionOptions.length - 1].versionCode
      await this.changeWeek()
      this.queryClick(1)
    },
    replenishment() {
      if (this.form.replenishCycle < 0) {
        this.form.replenishCycle = 0
      }
      if (this.form.replenishCycle > 180) {
        this.form.replenishCycle = 180
      }
      if (this.form.replenishCycle !== this.originalValue) {
        this.handleCycle()
      }
    },
    handleCycle() {
      const confirmationMessage = `将修改翻单周期为${this.form.replenishCycle}天，并重新生成翻单建议，是否继续？`
      this.$confirm(confirmationMessage, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$message({
          type: 'success',
          message: '修改成功!'
        })
        this.originalValue = this.form.replenishCycle
        this.queryClick()
        trackBtnClickEvent(`${handleBreadTitle(this.$route)}${this.activeName === 'first' ? '快船翻单' : '普船翻单'}翻单周期修改次数`)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改'
        })
        this.form.replenishCycle = this.originalValue
      })
    },
    async toModify() {
      const data = []
      this.tableDatas.map(list => {
        if (list.checked && Array.isArray(list.colorDTOList)) {
          list.colorDTOList.map(v => {
            if (Array.isArray(v.sizeDTOList)) {
              v.sizeDTOList.map(ee => data.push(ee))
            }
          })
        } else {
          Array.isArray(list.colorDTOList) && list.colorDTOList.map(v => {
            if (v.checked) {
              if (Array.isArray(v.sizeDTOList)) {
                v.sizeDTOList.map(ee => data.push(ee))
              }
            } else {
              Array.isArray(v.sizeDTOList) && v.sizeDTOList.map(ee => {
                if (ee.checked) {
                  data.push(ee)
                }
              })
            }
          })
        }
      })
      if (!data.length) {
        return this.$message.warning('请选择数据')
      } else if (data.some(e => e.stockChannelName !== data[0]?.stockChannelName)
      ) {
        return this.$message.warning('选择的Sku均须为同一备货渠道！')
      } else {
        this.getProduce(data, () => {
          trackBtnClickEvent(`${handleBreadTitle(this.$route)}${this.activeName === 'first' ? '快船翻单' : '普船翻单'}翻单按钮操作${data.length}条数据`)
        })
      }
    },
    async getProduce(params, callback) {
      if (params.length) {
        try {
          this.getProduceloading = true
          const { code, datas } = await Toproduce(params)
          if (code === 0) {
            this.$store.dispatch('pages/setflipOrderData', {
              produceData: JSON.stringify(params),
              result: JSON.stringify(datas),
              transferTypeDict: this.activeName,
              params: JSON.stringify(params)
            })
            this.$router.push({ name: 'ModifyFlipOrder', append: true,
              query: { type: this.form.type, activeName: this.activeName }
            })

            callback && callback()
          }
          this.getProduceloading = false
        } finally {
          this.getProduceloading = false
        }
      }
    },
    async toModifyDetail(row, type) {
      const sendBuryingPoint = (data) => {
        trackBtnClickEvent(`${handleBreadTitle(this.$route)}${this.activeName === 'first' ? '快船翻单' : '普船翻单'}翻单按钮操作${data.length}条数据`)
      }
      if (type === 'style') {
        const { colorDTOList } = row
        const sizeList = []
        colorDTOList.map(item => {
          item.sizeDTOList.map(i => sizeList.push(i))
        })
        this.getProduce(sizeList, () => {
          sendBuryingPoint(sizeList)
        })
      } else {
        const { sizeDTOList } = row
        this.getProduce(sizeDTOList, () => {
          sendBuryingPoint(sizeDTOList)
        })
      }
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    async changeWeek() {
      // 快船调接口，普船为正常
      if (this.form.type === 6) {
        const { datas } = await getProduceCycle()
        this.form.replenishCycle = datas
        this.originalValue = datas
      } else {
        this.form.replenishCycle = 14
        this.originalValue = 14
      }
    },
    async queryClick(flag) {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            this.Loading = true
            this.supplierTableLoading = true
            flag && flag === 1 ? this.pager.current = 1 : ''

            const { code, datas: { records, pager }} = await listByPage(this.queryParams, this.pager)
            if (code === 0) {
              this.pager = pager
              this.tableDatas = records.map(item => {
                item.colorDTOList.map(v => {
                  Object.assign(v, { defaultKey: `${v.color}${item.id}` })
                })
                return item
              })
              this.Loading = false
              this.supplierTableLoading = false
            }
          } finally {
            this.Loading = false
            this.supplierTableLoading = false
            this.$store.dispatch('saveForm/saveFlipOrder', { data: this.form, currentTab: this.activeName })
          }
        } else {
          this.$message.error('请检查必填项')
          return
        }
      })
    },
    async resetClick() {
      this.isNew = true
      this.form = this.$options.data.call(this).form
      if (this.activeName === 'second') {
        this.form.type = 7
      } else {
        this.form.type = 6
      }
      this.form.versionCode = this.dsbVertionOptions[this.dsbVertionOptions.length - 1].versionCode
      await this.changeWeek()
      this.queryClick(1)
    },
    // 三级联动
    cellClass(row) {
      if (row.columnIndex === 0) {
        return 'disableheadselection'
      }
    },
    handleSelectAll(rows) {
      this.tableDatas.map((item) => {
        const checked = rows.some(v => v.id === item.id)
        this.$set(item, 'checked', checked)
        item.colorDTOList.map((e) => {
          this.$set(e, 'checked', checked)
          this.$nextTick(() => {
            this.$refs['tableColor' + item.id ] && this.$refs['tableColor' + item.id ].toggleRowSelection(
              e,
              checked
            )
            e.sizeDTOList.map((v) => {
              this.$set(v, 'checked', checked)
              this.$nextTick(() => {
                this.$refs['tableSize' + e.defaultKey ] && this.$refs['tableSize' + e.defaultKey ].toggleRowSelection(
                  v,
                  checked
                )
              })
            })
          })
        })
      })
    },
    // 父级的选择事件
    handleSelect(rows, row) {
      const checked = rows.some(item => item.id === row.id)
      this.tableDatas.map((item, index) => {
        // 若选中父级，则给子级赋值true
        if (item.id === row.id) {
          this.$set(item, 'checked', checked)
          item.colorDTOList.map((e) => {
            this.$set(e, 'checked', checked)
            this.$nextTick(() => {
              this.$refs['tableColor' + row.id ] && this.$refs['tableColor' + row.id ].toggleRowSelection(
                e,
                checked
              )
              e.sizeDTOList.map((v) => {
                this.$set(v, 'checked', checked)
                this.$nextTick(() => {
                  this.$refs['tableSize' + e.defaultKey ] && this.$refs['tableSize' + e.defaultKey ].toggleRowSelection(
                    v,
                    checked
                  )
                })
              })
            })
          })
        }
      })
    },
    // Color级选择事件
    handleExpandSelect(rows, row) {
      this.tableDatas.map(item => {
        if (item.colorDTOList.some(ee => ee.defaultKey === row.defaultKey)) {
          item.colorDTOList.map(v => {
            Object.assign(v, { checked: rows.some(ee => ee.defaultKey === v.defaultKey) })
          })
        }
        const isChecked = item.colorDTOList.every(v => v.checked)
        this.$refs.multipleTable.toggleRowSelection(item, isChecked)
        Object.assign(item, { checked: isChecked })
      })
      const checked = rows.some(item => item.defaultKey === row.defaultKey)
      this.tableDatas.map((v) => {
        // 若选中父级，则给子级赋值true
        v.colorDTOList.map(item => {
          if (item.defaultKey === row.defaultKey) {
            this.$set(item, 'checked', checked)
            item.sizeDTOList.map((e) => {
              this.$set(e, 'checked', checked)
              this.$nextTick(() => {
                this.$refs['tableSize' + row.defaultKey ] && this.$refs['tableSize' + row.defaultKey ].toggleRowSelection(
                  e,
                  checked
                )
              })
            })
          }
        })
      })
    },
    // 展开行的事件
    handleSelectionChange(row) {
      Array.isArray(row.colorDTOList) && row.colorDTOList.map((e) => {
        if (e.checked) {
          this.$nextTick(() => {
            this.$refs['tableColor' + row.id ] && this.$refs['tableColor' + row.id ].toggleRowSelection(
              e,
              true
            )
          })
        }
      })
    },
    // size选择事件
    handleSizeSelect(rows, row) {
      const styleRow = this.tableDatas.filter(item =>
        item.colorDTOList.some(colorItem =>
          colorItem.sizeDTOList.includes(row)
        )
      )
      // const colorRow = styleRow.map(item => item.colorDTOList)
      this.tableDatas.map(v => {
        v.colorDTOList.map(item => {
          if (item.sizeDTOList.some(ee => ee.id === row.id)) {
            item.sizeDTOList.map(n => {
              Object.assign(n, { checked: rows.some(ee => ee.id === n.id) })
            })
          }
          // const isSize = item.sizeDTOList.some(v => v.colorChecked)
          const isSize = item.sizeDTOList.every(v => v.checked)
          Object.assign(item, { checked: isSize })
          if (isSize) {
            this.$refs['tableColor' + styleRow[0].id ].toggleRowSelection(item, true)
          } else {
            this.$refs['tableColor' + styleRow[0].id ].toggleRowSelection(item, false)
          }
        })
        const isColor = v.colorDTOList.every(a => a.checked)
        if (isColor) {
          this.$refs.multipleTable.toggleRowSelection(v, true)
        } else {
          v.checked = false
          this.$refs.multipleTable.toggleRowSelection(v, false)
        }
      })
    },
    // color展开行事件
    handleSelectionSizeChange(row) {
      Array.isArray(row.sizeDTOList) && row.sizeDTOList.map((e) => {
        if (e.checked) {
          this.$nextTick(() => {
            this.$refs['tableSize' + row.defaultKey ] && this.$refs['tableSize' + row.defaultKey ].toggleRowSelection(
              e,
              true
            )
          })
        }
      })
    },
    // 导出
    async exportDetail() {
      try {
        this.exportLoading = true
        const idList = this.cycleExport()
        const exportParams = Object.assign({}, this.queryParams, { idList })
        const { code, msg } = await exportProduce({ params: JSON.stringify(exportParams) })
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          trackExportEvent(`${handleBreadTitle(this.$route)}-${this.activeName === 'first' ? '快船翻单' : '普船翻单'}导出${checkedList.length}条数据`)
        }
        this.exportLoading = false
      } finally {
        this.exportLoading = false
      }
    },
    cycleExport() {
      const data = []
      this.tableDatas.map(list => {
        if (list.checked && Array.isArray(list.colorDTOList)) {
          // list.colorDTOList.map(v => data.push(v.id))
          list.colorDTOList.map(v => {
            if (Array.isArray(v.sizeDTOList)) {
              v.sizeDTOList.map(ee => data.push(ee.id))
            }
          })
        } else {
          Array.isArray(list.colorDTOList) && list.colorDTOList.map(v => {
            if (v.checked) {
              // data.push(v.id)
              if (Array.isArray(v.sizeDTOList)) {
                v.sizeDTOList.map(ee => data.push(ee.id))
              }
            } else {
              Array.isArray(v.sizeDTOList) && v.sizeDTOList.map(ee => {
                if (ee.checked) {
                  data.push(ee.id)
                }
              })
            }
          })
        }
      })
      return data
    },
    getVersionCode(val) {
      try {
        this.getVersionLoading = true
        this.queryClick()
        if (val !== this.dsbVertionOptions[this.dsbVertionOptions.length - 1].versionCode) {
          this.isNew = false
        } else {
          this.isNew = true
        }
      } finally {
        this.getVersionLoading = false
      }
    },
    // 下拉数据
    async _dsbVersionList() {
      try {
        this.Loading = true
        this.supplierTableLoading = true
        const { datas } = await dsbVersionList()
        this.dsbVertionOptions = datas
        this.form.versionCode = datas[datas.length - 1].versionCode
        this.queryClick(1)
      // 该条件必选，默认选择当前最新版本;选中版本即更新、查询数据
      } catch {
        this.Loading = false
        this.supplierTableLoading = false
      }
    },
    async _StockChannelList() {
      const { datas } = await StockChannelList('')
      this.StockChannelOptions = datas
    },
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    async _CategoryList() {
      const { datas } = await getBaseCategory()
      this.categoryOptions = datas
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    handleStyleChange(val) {
      Object.assign(this.form, { colorList: [], sizeList: [] })
      this._queryColorList(val)
      this._querySizeList(val)
    },
    async _queryColorList(id = '') {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id = '') {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    async _queryBrandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    async _querySeasonList() {
      const { datas } = await querySeasonList()
      this.seasonOptions = datas
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-table__expanded-cell[class*="cell"] {
  padding: 20px 0;
}
.el-table--border th:nth-child(2).cell {
  .el-table__expanded-icon {
    display: none;
    .el-icon-arrow-right {
      display: none;
    }
  }
}
// /deep/.el-table__header-wrapper  .el-checkbox{
//   display:none
// }
.expand-row {
  height: 44px;
  line-height: 44px;
  text-align: center;
  &.border {
    border-bottom: 1px solid #dfe6ec;
  }
}
.specifications-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.specifications {
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  line-height: 20px;
}
/deep/ .disableheadselection > .cell .el-checkbox__inner {
  display: none!important;
  }
.header_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.header_replenishCycle {
  margin-bottom: 0
}
</style>
