function getStyleColumns(type) {
  return [
    {
      prop: 'stockChannelName',
      label: '备货渠道',
      width: '150'
    },
    {
      prop: 'categoryName',
      label: '类别',
      width: '160',
      formatter: (row) => JSON.parse(row.categoryName).join('>')
    },
    {
      prop: 'styleName',
      label: 'Style',
      width: '120'
    },
    {
      prop: 'ltDays',
      label: 'LT',
      width: '120'
    },
    {
      prop: 'brandName',
      label: '品牌',
      width: '120'
    },
    {
      prop: 'seasonName',
      label: '季节',
      width: '120'
    },
    {
      prop: 'positionName',
      label: '定位',
      width: '120'
    },
    {
      prop: 'negativeCashFlag',
      label: '是否负现金流',
      width: '120'
    },
    {
      prop: 'vendorName',
      label: '供应商',
      width: '120'
    },
    {
      prop: 'gapQuantity',
      label: '翻单缺口'
    },
    {
      prop: 'turnOver',
      label: '翻单TurnOver（天）',
      width: '120'
    },
    {
      prop: 'turnOverSale',
      label: 'TurnOver预测数量',
      width: '120'
    },
    {
      prop: 'totalNetShippingDemand',
      label: '翻单需求',
      width: '120'
    },
    {
      prop: 'expectDeliveryDate',
      label: '期望交货日期',
      width: '120'
    },
    {
      prop: 'versionCode',
      label: '销售预测版本',
      width: '120'
    },
    {
      prop: 'totalPredictSales',
      label: '预测销量',
      width: '120'
    },
    {
      prop: 'totalReturn',
      label: '预测退货',
      width: '120'
    },
    {
      prop: 'platAvailableStock',
      label: '平台仓可用库存（期初）',
      width: '120'
    },
    {
      prop: 'platShippingStock',
      label: '平台仓在途库存（期初）',
      width: '120'
    },
    {
      prop: 'overSeaOwnAvailableStock',
      label: '海外自管仓可用库存（期初）',
      width: '120'
    },
    {
      prop: 'overSeaOwnShippingStock',
      label: '海外自管仓在途库存（期初）',
      width: '120'
    },
    {
      prop: 'overSeaOtherAvailableStock',
      label: '海外三方仓可用库存（期初）',
      width: '120'
    },
    {
      prop: 'overSeaOtherShippingStock',
      label: '海外三方仓在途库存（期初）',
      width: '120'
    },
    {
      prop: 'overseasNotSendStock',
      label: '海外仓未发库存（期初）',
      width: '120'
    },

    {
      prop: 'cnInStock',
      label: '国内仓在仓库存（期初）',
      width: '120'
    },
    {
      prop: 'platAvailableStockBf',
      label: '平台仓可用库存（到货前））'
    },
    {
      prop: 'platShippingStockBf',
      label: '平台仓在途库存（到货前）'
    },
    {
      prop: 'overSeaOwnAvailableStockBf',
      label: '海外自管仓可用库存（到货前）'
    },
    {
      prop: 'overSeaOwnShippingStockBf',
      label: '海外自管仓在途库存（到货前）'
    },
    {
      prop: 'overSeaOtherAvailableStockBf',
      label: '海外三方仓可用库存（到货前））'
    },
    {
      prop: 'overSeaOtherShippingStockBf',
      label: '海外三方仓在途库存（到货前）'
    },
    {
      prop: 'overseasNotSendStockBf',
      label: '海外仓未发库存（到货前）'
    },
    {
      prop: 'cnInStockBf',
      label: '国内仓在仓库存（到货前）'
    },
    {
      prop: 'rps',
      label: 'RPS',
      width: '120'
    },
    {
      prop: 'recentSale',
      label: '近30天销量',
      width: '120'
    },
    {
      prop: 'recentReturn',
      label: '近30天退货',
      width: '120'
    },
    {
      prop: 'mps',
      label: 'MPS',
      width: '120'
    },
    {
      prop: 'safeStockDays',
      label: '安全库存天数',
      width: '120'
    }

  ]
}

function getColorColumns(type) {
  return [
    {
      prop: 'color',
      label: 'Color'
    },
    {
      prop: 'turnOver',
      label: '翻单TurnOver（天）'
    },
    {
      prop: 'turnOverSale',
      label: 'TurnOver预测数量'
    },
    {
      prop: 'gapQuantity',
      label: '翻单缺口'
    },
    {
      prop: 'totalNetShippingDemand',
      label: '翻单需求'
    },
    {
      prop: 'expectDeliveryDate',
      label: '期望交货日期'

    },
    {
      prop: 'versionCode',
      label: '销售预测版本'

    },
    {
      prop: 'totalPredictSales',
      label: '预测销量'

    },
    {
      prop: 'totalReturn',
      label: '预测退货'

    },
    {
      prop: 'platAvailableStock',
      label: '平台仓可用库存（期初）'
    },
    {
      prop: 'platShippingStock',
      label: '平台仓在途库存(期初)'
    },
    {
      prop: 'overSeaOwnAvailableStock',
      label: '海外自管仓可用库存（期初）'
    },
    {
      prop: 'overSeaOwnShippingStock',
      label: '海外自管仓在途库存（期初）'
    },
    {
      prop: 'overSeaOtherAvailableStock',
      label: '海外三方仓可用库存（期初）'
    },
    {
      prop: 'overSeaOtherShippingStock',
      label: '海外三方仓在途库存（期初）'
    },
    {
      prop: 'overseasNotSendStock',
      label: '海外仓未发库存（期初）'
    },

    {
      prop: 'cnInStock',
      label: '国内仓在仓库存（期初）'

    },
    {
      prop: 'platAvailableStockBf',
      label: '平台仓可用库存（到货前））'
    },
    {
      prop: 'platShippingStockBf',
      label: '平台仓在途库存（到货前）'
    },
    {
      prop: 'overSeaOwnAvailableStockBf',
      label: '海外自管仓可用库存（到货前）'
    },
    {
      prop: 'overSeaOwnShippingStockBf',
      label: '海外自管仓在途库存（到货前）'
    },
    {
      prop: 'overSeaOtherAvailableStockBf',
      label: '海外三方仓可用库存（到货前））'
    },
    {
      prop: 'overSeaOtherShippingStockBf',
      label: '海外三方仓在途库存（到货前）'
    },
    {
      prop: 'overseasNotSendStockBf',
      label: '海外仓未发库存（到货前）'
    },
    {
      prop: 'cnInStockBf',
      label: '国内仓在仓库存（到货前）'
    },
    {
      prop: 'rps',
      label: 'RPS'

    },
    {
      prop: 'recentSale',
      label: '近30天销量'

    },
    {
      prop: 'recentReturn',
      label: '近30天退货'

    },
    {
      prop: 'mps',
      label: 'MPS'

    },
    {
      prop: 'safeStockDays',
      label: '安全库存天数'

    }

  ]
}

function getSizeColumns(type) {
  return [
    {
      prop: 'size',
      label: 'Size'
    },
    {
      prop: 'sku',
      label: 'Sku'
    },
    {
      prop: 'positionName',
      label: '定位',
      width: '120'
    },
    {
      prop: 'negativeCashFlag',
      label: '是否负现金流',
      width: '120'
    },
    {
      prop: 'turnOver',
      label: '翻单TurnOver（天）'
    },
    {
      prop: 'turnOverSale',
      label: 'Turnover预测数量'
    },
    {
      prop: 'gapQuantity',
      label: '翻单缺口'
    },
    {
      prop: 'totalNetShippingDemand',
      label: '翻单需求'
    },
    {
      prop: 'versionCode',
      label: '销售预测版本'

    },
    {
      prop: 'totalPredictSales',
      label: '预测销量'

    },
    {
      prop: 'totalReturn',
      label: '预测退货'

    },
    {
      prop: 'platAvailableStock',
      label: '平台仓可用库存（期初）'
    },
    {
      prop: 'platShippingStock',
      label: '平台仓在途库存（期初）'
    },
    {
      prop: 'overSeaOwnAvailableStock',
      label: '海外自管仓可用库存（期初）'
    },
    {
      prop: 'overSeaOwnShippingStock',
      label: '海外自管仓在途库存（期初）'
    },
    {
      prop: 'overSeaOtherAvailableStock',
      label: '海外三方仓可用库存（期初）'
    },
    {
      prop: 'overSeaOtherShippingStock',
      label: '海外三方仓在途库存（期初）'
    },
    {
      prop: 'overseasNotSendStock',
      label: '海外仓未发库存（期初）'
    },

    {
      prop: 'cnInStock',
      label: '国内仓在仓库存（期初）'
    },
    {
      prop: 'platAvailableStockBf',
      label: '平台仓可用库存（到货前）'
    },
    {
      prop: 'platShippingStockBf',
      label: '平台仓在途库存（到货前）'
    },
    {
      prop: 'overSeaOwnAvailableStockBf',
      label: '海外自管仓可用库存（到货前）'
    },
    {
      prop: 'overSeaOwnShippingStockBf',
      label: '海外自管仓在途库存（到货前）'
    },
    {
      prop: 'overSeaOtherAvailableStockBf',
      label: '海外三方仓可用库存（到货前））'
    },
    {
      prop: 'overSeaOtherShippingStockBf',
      label: '海外三方仓在途库存（到货前）'
    },
    {
      prop: 'overseasNotSendStockBf',
      label: '海外仓未发库存（到货前）'
    },
    {
      prop: 'cnInStockBf',
      label: '国内仓在仓库存（到货前）'
    },
    {
      prop: 'rps',
      label: 'RPS'

    },
    {
      prop: 'recentSale',
      label: '近30天销量'

    },
    {
      prop: 'recentReturn',
      label: '近30天退货'

    },
    {
      prop: 'mps',
      label: 'MPS'

    },
    {
      prop: 'safeStockDays',
      label: '安全库存天数'

    }

  ]
}

export { getStyleColumns, getColorColumns, getSizeColumns }
